import React from 'react';
import Icon from '../icon';

import icon from '../svg/filter.sprite.svg';

const IconFilter = (props) => {
	return <Icon {...props} sprite={icon} />;
};

IconFilter.propTypes = Icon.propTypes;
IconFilter.defaultProps = Icon.defaultProps;

export default IconFilter;
