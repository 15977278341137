import React from 'react';

const LogoMedium = () => (
	<svg
		width="106"
		height="32"
		viewBox="0 0 106 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M27.5779 8.90555C27.9669 8.67366 28.1086 8.17822 27.9118 7.77428C27.8706 7.68917 27.8155 7.61043 27.7476 7.54196C27.6848 7.47855 27.6136 7.42636 27.5368 7.38621L14.443 0.125383C14.3149 0.046784 14.1682 0.00349071 14.0185 0.000210092C13.8556 -0.00343887 13.6948 0.0405218 13.5559 0.12607L0.457849 7.38921C0.43192 7.40306 0.406774 7.41821 0.382503 7.43459C0.28136 7.50256 0.198571 7.58943 0.136232 7.68798C0.050262 7.82324 0.000371482 7.98434 0.000371482 8.15732V15.9864C0.000302458 15.991 0.000269839 15.9956 0.000273496 16.0002C0.000237978 16.045 0.00362481 16.0899 0.0105511 16.1347C0.0335159 16.2846 0.0943375 16.4218 0.182817 16.536L0.188049 16.5427C0.266389 16.6424 0.363664 16.7209 0.471608 16.7758L5.93573 19.8058L0.433653 23.0893C0.400288 23.1085 0.368093 23.13 0.33733 23.1538C-0.00322794 23.416 -0.10398 23.9013 0.118496 24.2843C0.123896 24.2936 0.12944 24.3027 0.135124 24.3117C0.171356 24.3693 0.214549 24.4229 0.264286 24.4711C0.322344 24.5274 0.387282 24.5744 0.456813 24.6115L13.5621 31.8779C13.6337 31.9211 13.7111 31.9533 13.7914 31.9737C13.859 31.9911 13.9275 31.9997 13.9956 32L14.0006 32C14.1211 32 14.2428 31.9745 14.3578 31.9205C14.3802 31.91 14.4021 31.8987 14.4234 31.8864L27.525 24.6212C27.7554 24.5047 27.9267 24.286 27.981 24.0237C27.9954 23.9549 28.0016 23.8841 27.9992 23.8131V16.0312C27.9995 16.021 27.9997 16.0107 27.9997 16.0004C27.9998 15.8606 27.9666 15.7188 27.8964 15.5874L27.892 15.5792C27.8031 15.4164 27.6702 15.2938 27.5167 15.2185L22.0647 12.1955L27.5779 8.90555ZM2.58993 23.8166L13.1446 29.669V23.8033L7.68518 20.7759L2.58993 23.8166ZM25.4104 8.18483L20.3151 11.2254L14.8558 8.19824V2.33207L25.4104 8.18483ZM14.8558 29.6684L26.2879 23.3294V17.4637L14.8558 23.803V29.6684ZM13.9996 9.70119L25.3604 16.0003L14 22.2998L2.63957 16.0002L13.9996 9.70119ZM1.71166 8.67171V14.537L13.1446 8.19758V2.33191L1.71166 8.67171Z"
		/>
		<path d="M76.7147 7H78.2851V20.6811H76.7147V7Z" />
		<path d="M74.2397 18.9681C73.7331 19.2126 73.2761 19.3315 72.8424 19.3315C71.8937 19.3315 71.4128 18.7761 71.4128 17.6815V12.6501H74.3166V11.2638H71.4128V9.04013H69.8426V11.2638H68.023V12.6501H69.8426V17.7548C69.8426 19.639 70.9358 20.8096 72.6954 20.8096C73.3877 20.8096 74.0798 20.6475 74.7527 20.3281L74.7863 20.3121L74.2802 18.9485L74.2397 18.9681Z" />
		<path d="M40.1791 15.1794C38.4987 14.7633 37.9318 14.3839 37.9318 13.6754C37.9318 12.9768 38.5732 12.5763 39.6918 12.5763C40.7582 12.5763 41.8334 12.9709 42.5682 13.6318L42.6035 13.6638L43.3645 12.5034L43.3841 12.4738L43.3574 12.4503C42.3434 11.5652 41.1163 11.1167 39.7102 11.1167C37.6559 11.1167 36.3796 12.0969 36.3796 13.6756C36.3796 15.1101 37.3782 15.9738 39.7183 16.5634C41.3152 16.9762 41.9664 17.4283 41.9664 18.1229C41.9664 18.8796 41.1653 19.35 39.8757 19.35C38.7788 19.35 37.7879 18.9961 36.7571 18.2373L36.7205 18.2102L36.0176 19.4257L36 19.4557L36.0269 19.4776C37.1398 20.3864 38.3989 20.828 39.8757 20.828C42.0997 20.828 43.5366 19.7516 43.5366 18.0856C43.5364 16.6894 42.4698 15.766 40.1791 15.1794Z" />
		<path d="M86.2365 16.5646L85.3159 19.0253L84.2169 16.5444L81.9017 11.2883L81.8911 11.264H80.1842L84.5397 21.0455L84.1604 22.0034C83.6987 23.1391 83.2614 23.5037 82.3603 23.5037C81.941 23.5037 81.5691 23.4367 81.2547 23.3046L81.2134 23.287L80.785 24.6514L80.8182 24.6662C81.3179 24.888 81.8675 25 82.452 25C83.9442 25 84.9432 24.2153 85.5959 22.5298L89.9176 11.2638H88.2491L86.2365 16.5646Z" />
		<path d="M92.4349 13.6754C92.4349 14.3839 93.0019 14.7633 94.6823 15.1794C96.9729 15.766 98.0396 16.6894 98.0396 18.0856C98.0396 19.7516 96.6025 20.828 94.3786 20.828C92.9016 20.828 91.6427 20.3864 90.5301 19.4776L90.5032 19.4557L90.5208 19.4257L91.2234 18.2102L91.2601 18.2373C92.2909 18.9961 93.2818 19.35 94.3786 19.35C95.6683 19.35 96.4694 18.8796 96.4694 18.1229C96.4694 17.4283 95.8182 16.9762 94.2212 16.5634C91.8811 15.9738 90.8825 15.1101 90.8825 13.6756C90.8825 12.0969 92.1588 11.1167 94.2131 11.1167C95.6193 11.1167 96.8464 11.5652 97.8605 12.4503L97.8873 12.4738L97.8678 12.5034L97.1066 13.6638L97.0714 13.6318C96.3365 12.9709 95.2614 12.5763 94.1949 12.5763C93.0764 12.5763 92.4349 12.9768 92.4349 13.6754Z" />
		<path d="M105.06 18.9487L105.019 18.9683C104.512 19.2128 104.055 19.3317 103.622 19.3317C102.673 19.3317 102.192 18.7763 102.192 17.6817V12.6503H105.096V11.264H102.192V9.04013H100.622V11.2638H98.8024V12.6501H100.622V17.7548C100.622 19.639 101.715 20.8096 103.475 20.8096C104.167 20.8096 104.859 20.6475 105.532 20.3281L105.566 20.3121L105.06 18.9487Z" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M57.3432 15.9806C57.3432 13.25 59.4258 11.0363 62.2818 11.0363C65.1154 11.0363 67.2203 13.25 67.2203 15.9806C67.2203 18.7113 65.1597 20.9017 62.2818 20.9017C59.3703 20.9017 57.3432 18.7113 57.3432 15.9806ZM58.9134 15.9806C58.9134 17.8893 60.373 19.4364 62.2816 19.4364C64.1903 19.4364 65.6497 17.8893 65.6495 15.9806C65.6495 14.072 64.1901 12.5249 62.2816 12.5249C60.373 12.5249 58.9134 14.0722 58.9134 15.9806Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M50.6892 11.0331C49.2985 11.0331 48.1435 11.5607 47.3218 12.4147V11.2638H45.7878V24.9078H47.3584V19.5851C48.1705 20.399 49.304 20.8987 50.6892 20.8987C53.5671 20.8987 55.6277 18.7082 55.6277 15.9776C55.6277 13.247 53.5228 11.0331 50.6892 11.0331ZM50.6892 19.4334C48.7806 19.4334 47.321 17.8861 47.321 15.9776C47.321 14.0689 48.7808 12.5218 50.6892 12.5218C52.5977 12.5218 54.0571 14.0689 54.0571 15.9776C54.0571 17.8861 52.5977 19.4334 50.6892 19.4334Z"
		/>
	</svg>
);

export default LogoMedium;
