import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import css from './style.module.scss';

const Icon = (props) => {
	const { sprite, mods, className, isCssModule, ...attr } = props;

	if (!sprite?.id) {
		return null;
	}

	// Safari не поддерживает lookbehind assertion
	const id = sprite.id.match(/_[\w-]+(?=\.)/)[0].replace(/_/, '');

	const moduleClass = isCssModule && cn(css.icon, className);

	return (
		<svg
			viewBox={sprite.viewBox}
			className={moduleClass || cn('icon', id, className, mods)}
			{...attr}
		>
			<use xlinkHref={`#${sprite.id}`} />
		</svg>
	);
};

Icon.propTypes = {
	isCssModule: PropTypes.bool,
	/**
	 * Спрайт иконки.
	 */
	sprite: PropTypes.shape({
		id: PropTypes.string.isRequired,
		viewBox: PropTypes.string.isRequired,
	}),
	/**
	 * Стилевые модификаторы.
	 */
	mods: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.string),
		PropTypes.string,
	]),
	/**
	 *
	 */
	className: PropTypes.string,
};

Icon.defaultProps = {
	isCssModule: false,
	sprite: undefined,
	mods: undefined,
	className: undefined,
};

export default Icon;
