import React from 'react';
import Icon from '../icon';

import map from '../svg/map.sprite.svg';

const IconMap = (props) => {
	return <Icon {...props} sprite={map} />;
};

IconMap.propTypes = Icon.propTypes;
IconMap.defaultProps = Icon.defaultProps;

export default IconMap;
