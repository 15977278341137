import React from 'react';
import Icon from '../icon';

import heart from '../svg/heart.sprite.svg';

const IconHeart = (props) => {
	return <Icon {...props} sprite={heart} />;
};

IconHeart.propTypes = Icon.propTypes;
IconHeart.defaultProps = Icon.defaultProps;

export default IconHeart;
