import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../reducers';

const useMainFiltersVisibility = () => {
	const dispatch = useDispatch();

	const setMainFiltersVisibility = useCallback(
		(payload) => {
			dispatch(actions.setMainFiltersVisible(payload));
		},
		[dispatch],
	);

	return {
		setMainFiltersVisibility,
	};
};

export default useMainFiltersVisibility;
