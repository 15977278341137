import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import LogoMain from './assets/main';
import LogoMedium from './assets/medium';
import LogoSmall from './assets/small';
import css from './style.module.scss';

const HeaderLogo = (props) => {
	const { adaptive, className } = props;

	const nodes = (
		<>
			<span className={cn(css.logo, css.logo_main, 'logo_main')}>
				<LogoMain />
			</span>
			<span className={cn(css.logo, css.logo_medium, 'logo_medium')}>
				<LogoMedium />
			</span>
			<span className={cn(css.logo, css.logo_small, 'logo_small')}>
				<LogoSmall />
			</span>
		</>
	);

	const blockClass = cn(css.block, className, {
		[css[`block_adapt_${adaptive}`]]: Boolean(adaptive),
	});

	return <span className={blockClass}>{nodes}</span>;
};

HeaderLogo.propTypes = {
	adaptive: PropTypes.oneOf(['header']),
	className: PropTypes.string,
};

HeaderLogo.defaultProps = {
	adaptive: undefined,
	className: undefined,
};

export default HeaderLogo;
