import { mapServerImg } from '../mapServerImg';
import reduceMetroColors from '../reduceMetroColors';

/**
 * @deprecated из-за смены интерфейса с `<Card>` на `<Space>` использовать
 * `mapServerSpace` с дополнительным (`additional`) маппером
 */
const mapSpaceItem = (favIds, _cityId, town, firstId) => (i, index) => {
	const metros = reduceMetroColors(i.metro);

	const result = {
		id: i.id,
		title: i.name,
		infoTag: i.isNonFit ? t('Не подходит по параметрам') : null,
		rating: i.rating ? `${i.rating}` : null,
		isFirstID: !Number.isNaN(firstId) && firstId === i.id,
		features:
			i.cardParam?.map((param) => ({
				name: param.name,
				text: param.text,
				isGreen: param.isPrice,
			})) || [],
		metros,
		// location: 'Адрес заведения',
		locationHref: undefined,
		imgs: i.media?.map(mapServerImg) || [],
		isLiked: favIds.includes(i.id),
		href: `/${i.cityDomain || town}/spaces/${i.id}`,
		description: i.description,
		coordinate: i.coordinate,
		category: i.venueName,
	};

	if (result.imgs[0]) {
		result.imgs[0].forceVisibility = index < 10;
	}

	return result;
};

/**
 * Функция для смены интерфейса с `Card` на `Space`
 * @param {object[]} items Массив объектов с интерфейсом `Card`
 * @param {object} additional Объект с дополнительными свойствами
 * @returns Массив объектов с интерфейсом `Space`
 * @deprecated устарело, из-за смены интерфейса с `<Card>` на `<Space>`
 * использовать `mapServerSpace` с дополнительным (`additional`) маппером
 */
export const remapForSpace = (item) => {
	const features = item.features.map(({ text, isGreen }) => ({
		text: text.replace('<sup>2</sup>', '²'),
		bgColor: isGreen ? 'green' : 'gray',
	}));

	const tags = item.rating
		? features.concat({
				text: item.rating,
				bgColor: 'gray',
				leftIcon: 'ratingStar',
		  })
		: features;

	const address = {
		...item?.address,
		metros: item.metros.map((metro) => ({
			name: metro.name,
			colors: Array.isArray(metro.color) ? metro.color : [metro.color],
		})),
	};

	const appearance = item.isFirstID ? 'firstId' : undefined;

	return {
		...item,
		name: item.title,
		linkTo: item.href,
		tags,
		address,
		appearance,
	};
};

export default mapSpaceItem;
