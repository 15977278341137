import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import NLink from '@src/components/NLink';
import HeaderSearch from '@src/components/HeaderSearch';
import HeaderLogo from '@src/components/HeaderLogo';
import FavBtn from '@src/components/FavBtn';
import FilterBtn from '@src/components/FilterBtn';

import css from './style.module.scss';

const Header = (props) => {
	const {
		isLinkOnLogo,
		likesQtty,
		eventType,
		guestQtty,
		eventDate,
		mods,
		favourLink,
		onFilterClick,
		city,
		placeholder,
		onClickFilterBtn,
		adaptive,
		filtersQtty,
		isMobileNonSticky,
		isDesktopNonSticky,
		isNonSticky,
		isFiltersVisible,
		state,
		onClickFav,
	} = props;

	React.useEffect(() => {
		window.dispatchEvent(new Event('updateDimensions'));
	}, []);

	const searchNodes = React.useMemo(() => {
		const result = [];
		if (city && !eventType) {
			result.push({ id: 'city', text: city });
		}
		if (eventType) {
			result.push({ id: 'eventType', text: eventType });
		}
		if (guestQtty) {
			result.push({ id: 'guestQtty', text: guestQtty });
		}
		if (eventDate) {
			result.push({ id: 'eventDate', text: eventDate });
		}
		if (placeholder) {
			result.push({
				id: 'placeholder',
				text: placeholder,
				isPurple: true,
			});
		}
		return result;
	}, [placeholder, eventType, city, eventDate, guestQtty]);

	return (
		<header
			id="app_header"
			className={cn(css.block, mods, {
				[css[`block_adapt_${adaptive}`]]: Boolean(adaptive),
				[css[`block_state_${state}`]]: Boolean(state),
				[css.block_filters]: isFiltersVisible,
				[css.mobileNonSticky]: isMobileNonSticky,
				[css.desktopNonSticky]: isDesktopNonSticky,
				[css.nonSticky]: isNonSticky,
			})}
		>
			<div className={css.wrap}>
				<NLink href={isLinkOnLogo ? '/' : null}>
					<a
						className={cn(css.logo, {
							[css.logo_active]: isLinkOnLogo,
						})}
					>
						<HeaderLogo />
					</a>
				</NLink>
				{onFilterClick && (
					<div
						className={css.search}
						onClick={onFilterClick}
						role="button"
						tabIndex={0}
					>
						<HeaderSearch nodes={searchNodes} />
					</div>
				)}
				<div className={css.rightSide}>
					{onClickFilterBtn && (
						<span
							className={css.filterBtn}
							onClick={onClickFilterBtn}
							role="button"
							tabIndex={0}
						>
							<FilterBtn count={filtersQtty} adaptive="header" />
						</span>
					)}
					{favourLink && (
						<NLink href={favourLink}>
							<a onClick={onClickFav}>
								<FavBtn count={likesQtty} adaptive="header" />
							</a>
						</NLink>
					)}
				</div>
			</div>
		</header>
	);
};

Header.propTypes = {
	isLinkOnLogo: PropTypes.bool,
	likesQtty: PropTypes.number,
	eventType: PropTypes.string,
	guestQtty: PropTypes.string,
	eventDate: PropTypes.string,
	mods: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.string),
		PropTypes.string,
	]),
	onFilterClick: PropTypes.func,
	favourLink: PropTypes.string,
	city: PropTypes.string,
	placeholder: PropTypes.string,
	isFiltersVisible: PropTypes.bool,
	adaptive: PropTypes.oneOf([null, 'default', 'homepage']),
	state: PropTypes.oneOf([null, 'large']),
	isMobileNonSticky: PropTypes.bool,
	isNonSticky: PropTypes.bool,
	isDesktopNonSticky: PropTypes.bool,
	filtersQtty: PropTypes.number,
	onClickFilterBtn: PropTypes.func,
	onClickFav: PropTypes.func,
};

Header.defaultProps = {
	isLinkOnLogo: false,
	likesQtty: undefined,
	eventType: undefined,
	guestQtty: undefined,
	eventDate: undefined,
	filtersQtty: undefined,
	mods: [],
	onFilterClick: undefined,
	favourLink: undefined,
	city: undefined,
	placeholder: undefined,
	isFiltersVisible: false,
	isMobileNonSticky: false,
	isNonSticky: false,
	isDesktopNonSticky: false,
	onClickFilterBtn: undefined,
	onClickFav: undefined,
	adaptive: null,
	state: null,
};

export default Header;
