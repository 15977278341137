import getFiltersQuery from '@src/containers/Filters/selectors/getFiltersQuery';
import getFavourites from '@src/containers/Session/selectors/getFavourites';
import { createSelector } from 'reselect';
import getSpacesForSpace from './getSpacesForSpace';
import { mapSpaces } from './mapSpaces';

export const containerState = (state) => state.SpacesListing;

export const loadSpacesData = createSelector(
	containerState,
	(state) => state.loadSpaces.data,
);

export const serverSpaceItems = createSelector(loadSpacesData, (data) => {
	if (!data) return [];
	const { list, entity } = data;
	return list.map((id) => entity[id]);
});

export const mappedSpaceItems = createSelector(
	serverSpaceItems,
	(s) => s.Session.cityFromUrl?.id,
	(s) => s.Session.params?.town,
	getFavourites,
	getFiltersQuery,
	mapSpaces,
);

/**
 * @deprecated устарело, использовать `getMappedSpaces`
 */
export const mappedSpaceItemsForSpace = createSelector(
	mappedSpaceItems,
	getSpacesForSpace,
);
