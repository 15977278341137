import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

const NLink = (props) => {
	const { children, isEmbeddedLink, href, ...rest } = props;

	if (isEmbeddedLink) {
		return (
			<Link prefetch={false} href={href} {...rest}>
				<a>{children}</a>
			</Link>
		);
	}

	if (!href || global.NO_ROUTING) return children;

	return <Link legacyBehavior prefetch={false} href={href} {...props} />;
};

NLink.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	href: PropTypes.string,
	/**
	 * Контроллер наличия встроенного `<a>`.
	 */
	isEmbeddedLink: PropTypes.bool,
};

NLink.defaultProps = {
	children: undefined,
	isEmbeddedLink: false,
	href: undefined,
};

export default NLink;
