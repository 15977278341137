import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Header from '@src/components/Header';

import css from './style.module.scss';

const LayoutBase = (props) => {
	const {
		children,
		headerProps,
		fixType,
		Header: HeaderRP,
		Footer,
		SeoBlock,
		HomepageSearchForm,
	} = props;
	const headerRef = React.useRef();
	const [isComplete, setIsComplete] = React.useState();
	React.useEffect(() => setIsComplete(true), []);

	return (
		<div className={cn(css.root, '--window-min-height')}>
			{HeaderRP && (
				<div
					ref={headerRef}
					className={cn(css.header, '--header-height', {
						[css[`header_fix_${fixType}`]]: !!fixType && isComplete,
					})}
				>
					<div
						className={cn(css.headerWrap, {
							'--scroll-fix-compensator': fixType !== 'profile',
						})}
					>
						<div className={css.centred}>
							<HeaderRP {...headerProps} />
						</div>
					</div>
				</div>
			)}

			{HomepageSearchForm && <HomepageSearchForm />}

			<main className={cn(css.main)}>
				{children && <div className={css.mainContent}>{children}</div>}
			</main>

			{SeoBlock && <SeoBlock />}
			{Footer && <Footer />}
		</div>
	);
};

LayoutBase.propTypes = {
	fixType: PropTypes.oneOf([null, 'default', 'profile']),
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	HomepageSearchForm: PropTypes.elementType,
	Header: PropTypes.elementType,
	Footer: PropTypes.elementType,
	SeoBlock: PropTypes.elementType,
	headerProps: PropTypes.shape(Header.type.propTypes),
};

LayoutBase.defaultProps = {
	fixType: 'default',
	children: undefined,
	Header: undefined,
	Footer: undefined,
	SeoBlock: undefined,
	HomepageSearchForm: undefined,
	headerProps: undefined,
};

export default LayoutBase;
