import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import IconFilter from '@src/components/Icon/set/IconFilter';

import css from './style.module.scss';

const FilterBtn = (props) => {
	const { count, state, adaptive } = props;
	const isEmpty = count === undefined;

	return (
		<span
			className={cn(css.block, {
				[css.block_empty]: isEmpty,
				[css.block_filled]: !isEmpty,
				[css[`block_state_${state}`]]: !!state,
				[css[`block_adapt_${adaptive}`]]: !!adaptive,
			})}
		>
			<IconFilter className={css.icon} />
			{!isEmpty && <span className={css.count}>{count}</span>}
		</span>
	);
};

FilterBtn.propTypes = {
	count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	state: PropTypes.oneOf([null, 'big']),
	adaptive: PropTypes.oneOf([null, 'header']),
};

FilterBtn.defaultProps = {
	count: undefined,
	state: null,
	adaptive: null,
};

export default FilterBtn;
