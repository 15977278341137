import React from 'react';

const LogoMain = () => (
	<svg
		width={28}
		height={32}
		viewBox="0 0 28 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M27.5779 8.90555C27.9669 8.67366 28.1086 8.17822 27.9118 7.77428C27.8706 7.68917 27.8155 7.61043 27.7476 7.54196C27.6848 7.47855 27.6136 7.42636 27.5368 7.38621L14.443 0.125383C14.3149 0.046784 14.1682 0.00349071 14.0185 0.000210092C13.8556 -0.00343887 13.6948 0.0405218 13.5559 0.12607L0.457849 7.38921C0.43192 7.40306 0.406774 7.41821 0.382503 7.43459C0.28136 7.50256 0.198571 7.58943 0.136232 7.68798C0.050262 7.82324 0.000371482 7.98434 0.000371482 8.15732V15.9864C0.000302458 15.991 0.000269839 15.9956 0.000273496 16.0002C0.000237978 16.045 0.00362481 16.0899 0.0105511 16.1347C0.0335159 16.2846 0.0943375 16.4218 0.182817 16.536L0.188049 16.5427C0.266389 16.6424 0.363664 16.7209 0.471608 16.7758L5.93573 19.8058L0.433653 23.0893C0.400288 23.1085 0.368093 23.13 0.33733 23.1538C-0.00322794 23.416 -0.10398 23.9013 0.118496 24.2843C0.123896 24.2936 0.12944 24.3027 0.135124 24.3117C0.171356 24.3693 0.214549 24.4229 0.264286 24.4711C0.322344 24.5274 0.387282 24.5744 0.456813 24.6115L13.5621 31.8779C13.6337 31.9211 13.7111 31.9533 13.7914 31.9737C13.859 31.9911 13.9275 31.9997 13.9956 32L14.0006 32C14.1211 32 14.2428 31.9745 14.3578 31.9205C14.3802 31.91 14.4021 31.8987 14.4234 31.8864L27.525 24.6212C27.7554 24.5047 27.9267 24.286 27.981 24.0237C27.9954 23.9549 28.0016 23.8841 27.9992 23.8131V16.0312C27.9995 16.021 27.9997 16.0107 27.9997 16.0004C27.9998 15.8606 27.9666 15.7188 27.8964 15.5874L27.892 15.5792C27.8031 15.4164 27.6702 15.2938 27.5167 15.2185L22.0647 12.1955L27.5779 8.90555ZM2.58993 23.8166L13.1446 29.669V23.8033L7.68518 20.7759L2.58993 23.8166ZM25.4104 8.18483L20.3151 11.2254L14.8558 8.19824V2.33207L25.4104 8.18483ZM14.8558 29.6684L26.2879 23.3294V17.4637L14.8558 23.803V29.6684ZM13.9996 9.70119L25.3604 16.0003L14 22.2998L2.63957 16.0002L13.9996 9.70119ZM1.71166 8.67171V14.537L13.1446 8.19758V2.33191L1.71166 8.67171Z"
		/>
	</svg>
);

export default LogoMain;
