/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { useActions as useMapActions } from '@src/containers/Map/hooks';
import { serverSpaceItems } from '@src/containers/SpacesListing/selectors';
import { useReachGoal } from '@src/libs/hooks/useReachGoal';

import Footer from '..';

const FooterCDefault = (props) => {
	const { noMap } = props;
	const { openMapInListing } = useMapActions();
	const cards = useSelector(serverSpaceItems);
	const { reachGoal } = useReachGoal();
	const description = t(
		'Сервис бронирования лофтов и студий для мероприятий. Через наш сайт вы найдёте площадку под любой тип мероприятия.',
	);

	const links = React.useMemo(
		() => [
			{
				id: 1,
				url: '/sitemap/',
				label: 'Карта сайта',
			},
			{
				id: 2,
				url: '/privacy/',
				label: 'Политика конфиденциальности',
			},
		],
		[],
	);

	const handleClickMap = React.useCallback(() => {
		reachGoal('click_show_map_btn');
		openMapInListing();
	}, [openMapInListing, reachGoal]);

	const onClickMap = cards.length && !noMap ? handleClickMap : undefined;

	return (
		<Footer
			links={links}
			onClickMap={onClickMap}
			description={description}
		/>
	);
};

export default FooterCDefault;
