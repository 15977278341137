/* eslint-disable no-console */
import { getComMethods } from '@src/containers/Session/selectors/getComMethods';
import React from 'react';
import { useSelector } from 'react-redux';

export const ANALYTICS_GOAL = {
	// Нажатие кнопки «Запрос на бронирование». Профиль пространства
	click_booking_btn: 'click_booking_btn',
	// Нажатие кнопки «Позвонить» или «Показать телефон». Профиль пространства
	click_call_btn: 'click_call_btn',
	// Нажатие кнопки «Задать вопрос». Профиль пространства
	click_ask_btn: 'click_ask_btn',
	// Нажатие баннера «Поможем найти подходящую площадку». Листинг
	click_offer_banner: 'click_offer_banner',
	// Нажатие кнопки-сердечка. Листинг (карточка), Профиль пространства
	click_add_favorites_btn: 'click_add_favorites_btn',
	// Нажатие кнопки-сердечка перехода в раздел «Избранное». Любая страница
	click_favorites_btn: 'click_favorites_btn',
	// Нажатие на панель поиска. Любая страница
	click_search_panel: 'click_search_panel',
	// Нажатие на кнопку «Фильтры». Листинг
	click_filters_btn: 'click_filters_btn',
	// Отправка формы «Запрос на бронирование». Где вызвана
	send_booking_form: 'send_booking_form',
	// Отправка формы «Задать вопрос». Где вызвана
	send_ask_form: 'send_ask_form',
	// Применение поиска. Где вызвана
	send_search_form: 'send_search_form',
	// Применение фильтров. Где вызвана
	send_filters_form: 'send_filters_form',
	// Отправка формы «Заявка на подбор площадки». Где вызвана
	send_offer_form: 'send_offer_form',
};

export const useReachGoal = () => {
	const ab_com_methods = useSelector(getComMethods);
	const reachGoal = React.useCallback(
		(goal, params = {}) => {
			const combinedParams = { ...params };
			if (ab_com_methods) combinedParams.ab_com_methods = ab_com_methods;
			if (window.ym) {
				window.ym(96001190, 'reachGoal', goal, combinedParams);
			}

			console.log('reachGoal', {
				reachGoal: goal,
				params: combinedParams,
			});
		},
		[ab_com_methods],
	);

	return {
		reachGoal,
	};
};
