import React from 'react';

const LogoSmall = () => (
	<svg
		width="71"
		height="18"
		viewBox="0 0 71 18"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.9574 4.37414C16.0691 4.60115 15.988 4.87915 15.7659 5.00937L12.6141 6.85994L15.7309 8.56043C15.8196 8.60319 15.8961 8.67303 15.9469 8.76592C15.9878 8.84043 16.0072 8.92092 16.0071 9.00026C16.0071 9.00609 16.007 9.01192 16.0068 9.01775V13.3947C16.0082 13.4355 16.0045 13.4761 15.9959 13.5157C15.9643 13.6622 15.8668 13.7842 15.7358 13.8494L8.24547 17.9362C8.2339 17.9428 8.22203 17.9489 8.20988 17.9545C8.14362 17.9854 8.07347 18 8.00404 18L8.00117 18C7.96199 17.9998 7.92252 17.9949 7.88361 17.985C7.83802 17.9735 7.79414 17.9555 7.75347 17.9314L0.261274 13.844C0.221525 13.8231 0.184401 13.7967 0.15121 13.765C0.122776 13.7379 0.0980835 13.7077 0.0773704 13.6753C0.0741208 13.6703 0.0709513 13.6651 0.0678642 13.6599C-0.0593215 13.4445 -0.00172329 13.1715 0.192968 13.024C0.210554 13.0106 0.22896 12.9985 0.248034 12.9877L3.39348 11.1408L0.269728 9.43639C0.20805 9.4055 0.152466 9.36137 0.107692 9.30536L0.103713 9.30033C0.0539264 9.23671 0.0196103 9.16047 0.00638876 9.07722C0.00227083 9.05156 0.000257711 9.02578 0.000278418 9.00012C0.000276285 8.99747 0.000295745 8.99483 0.000336878 8.99218V4.58849C0.000336878 4.49119 0.0288584 4.40057 0.0780061 4.32449C0.113644 4.26906 0.160973 4.22019 0.218795 4.18195C0.23267 4.17274 0.247046 4.16422 0.261869 4.15643L7.74982 0.0708878C7.82929 0.022747 7.9213 -0.00197231 8.0145 0.000122999C8.09998 0.00200516 8.18373 0.0263445 8.25691 0.0705015L15.7425 4.15478C15.7858 4.17708 15.8261 4.20599 15.8616 4.24106C15.9014 4.28017 15.9335 4.3253 15.9574 4.37414ZM7.51464 16.6888L1.48074 13.3968L4.39361 11.6864L7.51464 13.3893V16.6888ZM11.6139 6.31427L14.5268 4.60397L8.49295 1.31178V4.6115L11.6139 6.31427ZM15.0285 13.1228L8.49295 16.6885V13.3892L15.0285 9.82332V13.1228ZM14.4982 9.00014L8.00347 5.45692L1.50912 9.00013L8.00368 12.5437L14.4982 9.00014ZM0.978652 8.17707V4.87784L7.51464 1.3117V4.61114L0.978652 8.17707Z"
		/>
		<path d="M49.7508 2.51786H50.8746V12.3079H49.7508V2.51786Z" />
		<path d="M47.9797 11.0821C47.6172 11.2571 47.2902 11.3421 46.9799 11.3421C46.3009 11.3421 45.9568 10.9447 45.9568 10.1614V6.56097H48.0348V5.56896H45.9568V3.97775H44.8332V5.56896H43.5312V6.56097H44.8332V10.2139C44.8332 11.5622 45.6155 12.3998 46.8746 12.3998C47.3701 12.3998 47.8653 12.2839 48.3469 12.0553L48.3709 12.0438L48.0087 11.0681L47.9797 11.0821Z" />
		<path d="M23.6064 8.37091C22.4039 8.07319 21.9982 7.8017 21.9982 7.29469C21.9982 6.79477 22.4572 6.50821 23.2576 6.50821C24.0208 6.50821 24.7901 6.79057 25.316 7.26352L25.3412 7.28642L25.8858 6.45603L25.8999 6.43487L25.8807 6.41805C25.1551 5.78464 24.277 5.46373 23.2708 5.46373C21.8008 5.46373 20.8875 6.16512 20.8875 7.29483C20.8875 8.32133 21.6021 8.93938 23.2766 9.36132C24.4194 9.65672 24.8854 9.98024 24.8854 10.4773C24.8854 11.0188 24.3121 11.3553 23.3892 11.3553C22.6044 11.3553 21.8953 11.1021 21.1577 10.5591L21.1314 10.5397L20.6285 11.4095L20.6159 11.431L20.6351 11.4467C21.4315 12.097 22.3325 12.413 23.3892 12.413C24.9808 12.413 26.009 11.6428 26.009 10.4506C26.0089 9.45148 25.2456 8.79067 23.6064 8.37091Z" />
		<path d="M56.5645 9.36219L55.9058 11.123L55.1193 9.34769L53.4626 5.5865L53.455 5.5691H52.2336L55.3503 12.5687L55.0788 13.2541C54.7485 14.0668 54.4356 14.3277 53.7907 14.3277C53.4907 14.3277 53.2246 14.2798 52.9996 14.1852L52.97 14.1726L52.6635 15.149L52.6872 15.1596C53.0448 15.3183 53.4381 15.3984 53.8564 15.3984C54.9242 15.3984 55.6391 14.8369 56.1061 13.6308L59.1986 5.56896H58.0047L56.5645 9.36219Z" />
		<path d="M61 7.29469C61 7.8017 61.4057 8.07319 62.6082 8.37091C64.2474 8.79067 65.0107 9.45148 65.0107 10.4506C65.0107 11.6428 63.9823 12.413 62.3909 12.413C61.334 12.413 60.4331 12.097 59.637 11.4467L59.6177 11.431L59.6303 11.4095L60.1331 10.5397L60.1593 10.5591C60.897 11.1021 61.606 11.3553 62.3909 11.3553C63.3138 11.3553 63.8871 11.0188 63.8871 10.4773C63.8871 9.98024 63.4211 9.65672 62.2783 9.36132C60.6037 8.93938 59.8892 8.32133 59.8892 7.29483C59.8892 6.16512 60.8025 5.46373 62.2725 5.46373C63.2787 5.46373 64.1568 5.78464 64.8825 6.41805L64.9017 6.43487L64.8878 6.45603L64.343 7.28642L64.3178 7.26352C63.792 6.79057 63.0226 6.50821 62.2595 6.50821C61.4591 6.50821 61 6.79477 61 7.29469Z" />
		<path d="M70.034 11.0682L70.0051 11.0823C69.6425 11.2572 69.3154 11.3423 69.0051 11.3423C68.3263 11.3423 67.9822 10.9448 67.9822 10.1616V6.56111H70.06V5.56911H67.9822V3.97775H66.8586V5.56896H65.5565V6.56097H66.8586V10.2139C66.8586 11.5622 67.6407 12.3998 68.9 12.3998C69.3954 12.3998 69.8907 12.2839 70.3722 12.0553L70.3963 12.0438L70.034 11.0682Z" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M35.8888 8.9443C35.8888 6.99029 37.3791 5.40618 39.4228 5.40618C41.4505 5.40618 42.9568 6.99029 42.9568 8.9443C42.9568 10.8983 41.4822 12.4658 39.4228 12.4658C37.3394 12.4658 35.8888 10.8983 35.8888 8.9443ZM37.0124 8.9443C37.0124 10.3101 38.0569 11.4172 39.4227 11.4172C40.7885 11.4172 41.8329 10.3101 41.8327 8.9443C41.8327 7.57848 40.7884 6.47139 39.4227 6.47139C38.0569 6.47139 37.0124 7.57863 37.0124 8.9443Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M31.1273 5.40387C30.1321 5.40387 29.3056 5.78145 28.7176 6.39254V5.56896H27.6199V15.3325H28.7438V11.5236C29.3249 12.106 30.136 12.4636 31.1273 12.4636C33.1867 12.4636 34.6612 10.8961 34.6612 8.94213C34.6612 6.98812 33.155 5.40387 31.1273 5.40387ZM31.1273 11.415C29.7615 11.415 28.717 10.3078 28.717 8.94213C28.717 7.57631 29.7616 6.46922 31.1273 6.46922C32.493 6.46922 33.5373 7.57631 33.5373 8.94213C33.5373 10.3078 32.493 11.415 31.1273 11.415Z"
		/>
	</svg>
);

export default LogoSmall;
