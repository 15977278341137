import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import IconSearch from '@src/components/Icon/set/IconSearch';
import css from './style.module.scss';

const HeaderSearch = (props) => {
	const { nodes } = props;
	return (
		<div className={css.block}>
			<div className={css.wrap}>
				<IconSearch className={css.icon} />
				{nodes.length > 0 && (
					<div className={css.content}>
						{nodes.map((n) => {
							return (
								<div
									key={n.id}
									className={cn(css.node, {
										[css.node_purple]: n.isPurple,
									})}
								>
									{n.text}
								</div>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
};

HeaderSearch.propTypes = {
	nodes: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			text: PropTypes.string,
			isPurple: PropTypes.bool,
		}),
	),
};

HeaderSearch.defaultProps = {
	nodes: [],
};

export default HeaderSearch;
