export const mapServerImg = (img) => {
	if (typeof img === 'number') {
		return { src: img };
	}

	return {
		src: img.url,
		width: img.width,
		height: img.height,
	};
};
