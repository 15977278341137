import React from 'react';
import Icon from '../icon';

import search from '../svg/search.sprite.svg';

const IconSearch = (props) => {
	return <Icon {...props} sprite={search} />;
};

IconSearch.propTypes = Icon.propTypes;
IconSearch.defaultProps = Icon.defaultProps;

export default IconSearch;
