import React from 'react';
import { useSelector } from 'react-redux';
import { getTitles } from '@src/containers/Session/selectors/getTitles';

import SeoBlock from '..';

const SeoBlockCDefault = () => {
	const seoTitles = useSelector(getTitles);
	const seo = seoTitles.text;
	return seo ? <SeoBlock seo={seo} /> : null;
};

export default SeoBlockCDefault;
