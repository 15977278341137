import React from 'react';
import PropTypes from 'prop-types';
import css from './style.module.scss';

const SeoBlock = (props) => {
	const { seo } = props;
	return (
		<div className={css.block}>
			<div className={css.content}>
				<div
					className={css.contentText}
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: seo }}
				/>
			</div>
		</div>
	);
};

SeoBlock.propTypes = {
	seo: PropTypes.string,
};

SeoBlock.defaultProps = {
	seo: undefined,
};

export default SeoBlock;
