const reduceMetroColors = (argMetros) => {
	const metros = argMetros || [];
	const uniqueMetros = new Set(metros.map((metro) => metro.name));

	if (metros.length === uniqueMetros.size) {
		return metros;
	}

	const result = Array.from(uniqueMetros).map((name) => {
		const colors = metros
			.filter((metro) => metro.name === name)
			.map((metro) => metro.color);

		return {
			name,
			color: colors.length > 1 ? colors : colors[0],
			colors,
		};
	});

	return result;
};

export default reduceMetroColors;
