import React from 'react';
import PropTypes from 'prop-types';
import IconMap from '@src/components/Icon/set/IconMap';
import cn from 'classnames';
import css from './style.module.scss';
import NLink from '../NLink';

const Footer = ({ description, currentYear, onClickMap, links }) => {
	return (
		<footer className={cn(css.block)}>
			<div className={css.wrap}>
				<div className={css.wrapContent}>
					<div className={css.copyright}>
						&copy; {currentYear} Spotlyst
					</div>
					<div className={css.description}>{description}</div>
					{links.length > 0 && (
						<div className={css.links}>
							{links.map((l) => {
								if (!l.url) {
									return <span key={l.id}>{l.label}</span>;
								}
								return (
									<span key={l.id} className={css.linkItem}>
										<NLink href={l.url}>
											<a className={css.link}>
												{l.label}
											</a>
										</NLink>
									</span>
								);
							})}
						</div>
					)}
					<div className={cn(css.btns, css.btns_fixed)}>
						{onClickMap && (
							<span
								role="link"
								tabIndex={0}
								className={css.mapBtn}
								onClick={onClickMap}
							>
								<IconMap className={css.mapIcon} />{' '}
								<span className={css.mapLabel}>
									{t('Карта')}
								</span>
							</span>
						)}
					</div>
				</div>
			</div>
		</footer>
	);
};

Footer.propTypes = {
	description: PropTypes.string,
	currentYear: PropTypes.number,
	links: PropTypes.arrayOf(
		PropTypes.shape({
			url: PropTypes.string,
			label: PropTypes.string,
		}),
	),
	onClickMap: PropTypes.func,
};

Footer.defaultProps = {
	description: undefined,
	links: [],
	currentYear: new Date().getFullYear(),
	onClickMap: undefined,
};

export default Footer;
