import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useMainFiltersVisibility from '@src/containers/Filters/hooks/useMainFiltersVisibility';
import { actions } from '../reducers';

export const useActions = () => {
	const dispatch = useDispatch();

	const { setMainFiltersVisibility } = useMainFiltersVisibility();

	const resetState = useCallback(() => {
		dispatch(actions.reset());
	}, [dispatch]);

	const setActiveMarkerId = useCallback(
		(payload) => {
			dispatch(actions.setActiveMarkerId(payload));
		},
		[dispatch],
	);

	const toggleMapLayer = useCallback(
		(payload) => {
			dispatch(actions.toggleMapLayer(payload));
		},
		[dispatch],
	);

	const setInitCoords = useCallback(
		(payload) => {
			dispatch(actions.setInitCoords(payload));
		},
		[dispatch],
	);

	const openMapInListing = useCallback(
		(payload = {}) => {
			const { id, coords } = payload;
			setActiveMarkerId({ id: id || null });
			toggleMapLayer({ isOpen: true, coords: coords || null });
			setMainFiltersVisibility(false);
		},
		[setActiveMarkerId, toggleMapLayer, setMainFiltersVisibility],
	);

	return {
		resetState,
		setActiveMarkerId,
		toggleMapLayer,
		setInitCoords,
		openMapInListing,
	};
};

export default useActions;
